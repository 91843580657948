@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
/* app */

html, body {
    height: 100% !important;
}

/* table responsive */

.example-container {
    display: flex;
    flex-direction: column;
    max-height: 550px;
    min-width: 200px;
}

.mat-table {
    overflow: auto;
    max-height: 550px;
    z-index: 1;
}

.mat-header-row {
    background-color: #1C3775;
}

.mat-header-cell {
    color: white;
}

.mobile-label {
    display: none;
}

@media(max-width: 1180px) {
    mat-cell:first-of-type {
        padding-left: 0 !important;
    }

    .mobile-label {
        width: 80px;
        display: inline-block;
        font-weight: bold;
    }

    .mobileHeader {
        display: none;
    }

    .mat-row {
        flex-direction: column;
        align-items: start;
        padding: 8px 24px;
    }
}

.hover-row:hover {
    background-color: #E4F1F5;
}


.mat-paginator {
    border: 1px solid #1C3775;
    border-radius: 10px;
}

/* mat-form-field hover */

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgb(0, 0, 0, 0.12)
}

/* paginator on mobile */

@media(max-width: 470px) {
    .mat-paginator-range-label {
        margin: 0 10px 0 10px !important;
    }
}

/* npt-template style */

/* snackbar */

.ERROR {
    background-color: #FF0000 !important;
    color: white !important;
}

.WARN {
    background-color: #eed202 !important;
    color: white !important;
}

.INFO {
    background-color: #5cb85c !important;
    color: white !important;
}

.mat-simple-snackbar-action {
    color: white !important;
}

/* control map */

.ol-control button {
    display: block;
    margin: 1px;
    padding: 0;
    color: black;
    font-size: 1.14em;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    height: 40px;
    width: 100px;
    line-height: .4em;
    background-color: rgba(0, 60, 136, 0.5);
    border: none;
    border-radius: 2px;
}

/* .material-icons {
    display: none;
} */

#activeVehicle {
    color: #1C3775;
    background-color: white;
    width: 130px;
    .icon-Pin-rounded::before {
        color: #4CAE50;
    }
    &:hover {
        border: 2px solid #1C3775;
        border-radius: 2px;
    }
}

#warningVehicle {
    color: #1C3775;
    background-color: white;
    width: 180px;
    .icon-Pin-rounded::before {
        color: #FFBF07;
    }
    &:hover {
        border: 2px solid #1C3775;
        border-radius: 2px;
    }
}

#errorVehicle {
    color: #1C3775;
    background-color: white;
    width: 230px;
    .icon-Pin-rounded::before {
        color: #F54236;
    }
    &:hover {
        border: 2px solid #1C3775;
        border-radius: 2px;
    }
}

/* slide toogle  */

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #91D6AC;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: white;
}

.mat-slide-toggle-bar {
    height: 20px !important;
}

.mat-slide-toggle-thumb-container {
    top: 0px !important;
}

/* icons */

.redIcon:before {
    color: #F54236;
}

.greenIcon:before {
    color: #4CAE50;
}

.yellowIcon:before {
    color: #eed202;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

/* .backgroundImg1 {
    z-index: 1;
    background: url('src/assets/images/image_movyon.png') no-repeat right;
} */